<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="操作类型">
                <a-select placeholder="请选择操作类型" v-model="queryParam.useType" style="width: 50%" allow-clear>
                  <a-select-option :value="1">
                    开门
                  </a-select-option>
                  <a-select-option :value="2">
                    停止
                  </a-select-option>
                  <a-select-option :value="3">
                    关门
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="门编码" prop="devCode" style="width: 50%">
                <a-input v-model="queryParam.devCode" placeholder="请输入设备编码" allow-clear />
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="24">
              <a-form-item label="用户名称" prop="userName" style="width: 50%">
                <a-input v-model="queryParam.userName" placeholder="请输入门名称" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <!-- <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a> -->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="handleExport">
          <a-icon type="download" />导出
        </a-button>
        <!-- <a-button type="primary" @click="handleExport" v-hasPermi="['tram:config:export']">
          <a-icon type="download" />导出
        </a-button> -->
        <table-setting :style="{ float: 'right' }" :table-size.sync="tableSize" v-model="columns" :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list" :pagination="false"
        :bordered="tableBordered">
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>

        <span slot="useType" slot-scope="text, record">
          <a-tag v-if="1 == record.useType">
            用户开门
          </a-tag>
          <a-tag v-if="2 == record.useType">
            用户停止
          </a-tag>
          <a-tag v-if="3 == record.useType">
            用户关门
          </a-tag>
          <a-tag v-if="14 == record.useType">
            门关到位
          </a-tag>
          <a-tag v-if="15 == record.useType">
            门开到位
          </a-tag>
          <a-tag v-if="16 == record.useType">
            半开
          </a-tag>
          <a-tag v-if="99 == record.useType">
            开门
          </a-tag>
          <a-tag v-if="100 == record.useType">
            停止
          </a-tag>
          <a-tag v-if="101 == record.useType">
            关门
          </a-tag>
        </span>
        <!-- <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['door:use/history:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['door:use/history:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['door:use/history:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['door:use/history:remove']">
            <a-icon type="delete" />删除
          </a>
        </span> -->
      </a-table>
      <!-- 分页 -->
      <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
        :total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange" @change="changeSize" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageUseHistory, listUseHistory, delUseHistory } from '@/api/door/useHistory'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'UseHistory',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        useType: null,
        devCode: null,
        userName: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
      {
          title: '公司id',
          dataIndex: 'companyId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户名',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备名称',
          dataIndex: 'devName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备编码',
          dataIndex: 'devCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作类型',
          dataIndex: 'useType',
          scopedSlots: { customRender: 'useType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询操作记录列表 */
    getList() {
      this.loading = true
      pageUseHistory(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        useType: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map(id => data.push({ "id": id }))
          return delUseHistory(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('door/door-use-history/export', {
            ...that.queryParam
          }, `操作记录_${new Date().getTime()}.xlsx`)
        },
        onCancel() { }
      })
    }
  }
}
</script>
